/**
 * This file is being used as reducer for AuthReducer
 * @exports Auth Reducer as default
 * @author {Cognizant Technology Solutions}
 * @flow
 */
import { createSlice } from '@reduxjs/toolkit';

export const authSlice: Object = createSlice({
  name: 'auth',
  initialState: {
    data: {},
    sessionExpired: false,
  },
  reducers: {
    setAuthData: (state, action) => {
      let parsedToken = null;

      if (action.payload['okta-token-storage']) {
        parsedToken = JSON.parse(action.payload['okta-token-storage']);
      }
      
      const claims = parsedToken
        ? parsedToken
        && parsedToken.idToken
        && parsedToken.idToken.claims
        : {};

      let oktaUserGroupMapping = [];

      if (claims && claims.groups) {
        oktaUserGroupMapping = Array.isArray(claims.groups) ? claims.groups : [claims.groups];
      }

      const userDetails = {
        group: [...oktaUserGroupMapping],
        email: (claims && claims.email) || '',
        name: (claims && claims.name) || '',
        userName: (claims && claims.legacy_username) || ''
      };

      const tokenData = {
        ...state.data.parsedToken,
        ...parsedToken
      };
      
      state.data = {
        ...state.data,
        ['okta-token-storage']: JSON.stringify(tokenData),
        parsedToken: {
          ...state.data.parsedToken,
          ...parsedToken
        },
        userDetails: {
          ...state.data.userDetails,
          ...(parsedToken && parsedToken.idToken && {...userDetails })
        },
      };
    },
    setSessionTimeout: (state) => {
      state.sessionExpired = true;
    }
  },
});

export const { setAuthData, setSessionTimeout } = authSlice.actions;

export default authSlice.reducer;
