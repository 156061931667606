import axios from 'axios';
import store from '../store';


export default () => {
  /* eslint-disable no-param-reassign */
  axios.interceptors.request.use(
    (config) => {
      const {
        auth: { data: { parsedToken: { accessToken: { accessToken, expiresAt } } } },
      } = store.getState();
      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
        config.headers['x-api-key'] = process.env.REACT_APP_X_API_KEY;
      }
      if (expiresAt < new Date()/1000) {
        throw new axios.Cancel('Session Expired');
      }
      
      return config;
    },
  );

  axios.interceptors.response.use(
    (response) => response.data,
    (error) => {
      if (error instanceof axios.CanceledError && error.message === 'Session Expired') {
        store.dispatch({
          type: 'auth/setSessionTimeout',
        });
      }
      return Promise.reject((error && error.response && error.response.data) || 'Something went wrong.');
    }
  );
};