/**
 * File contains static string error, warnings, label text or info messages.
 * The file can be imported directly wherever required.
 * @flow
 * @author {Cognizant Technology Solution}
 */
const defaultText = {
  appTitle: 'GTIN Creator',
  assignGtinButtonLabel: 'Assign GTIN for Selected Items',
  backToDashboardText: 'Go back to Dashboard.',
  errorTxt: 'ERROR',
  errorDescription: 'Oops..Something Went Wrong! Please try again later.',
  error: 'ERROR',
  authFailed: 'Access Denied',
  authMsg:
    'You don\'t have permission to view this application. Please contact the GTIN system administrator with this email ID: Lst-NikeTech.GoaL.GTIN.Support@Nike.com.',
  authReq: 'Please wait while we authenticate your request.',
  fetchResult: 'Fetching search results',
  gtinAssignmentLoadingTxt: 'GTIN Assignment is in process.',
  footerTxt: '2022 Nike Inc. All Rights Reserved.',
  initiateReplenishmentLoaderText: 'Replenishment Request is in process',
  lookupSearchText: 'Inquiry/Lookup of Product',
  productAssignmentText: 'GTIN Assignment',
  profilePageTitle: 'Profile Details',
  productCodeFieldText: 'Search using Product code',
  gtinFieldText: 'Search using GTIN number',
  mfgRequiredErrMsg: 'MFG Id is required',
  mfgNumericErrMsg: 'MFG Id should be numeric and 6 digit long.',
  mgfMinimumRangeError: 'MFG Id cannot be 000000',
  procurementPageTitle: 'Procurement',
  productAssignmentPageTitle: 'GTIN Assignment',
  productLookupText: 'Product Lookup',
  productStyleTextInputLabel: 'Style Code',
  productColorTextInputLabel: 'Color Code',
  productCodeTextInputLabel: 'Product Codes',
  productGtinLabel: 'GTIN',
  productCodeRequiredErrMsg: 'Product Codes is required',
  productCodeLengthErrMsg: 'Only 20 Product Codes are allowed',
  productCodeValidationErrMsg:
    'Product Codes must be 10 Characters long. \n Product Code format should be <6 alphanumeric style code><-><3 alphanumeric color code>',
  searchButtonLabel: 'Search',
  waitSpinnerText: 'Please Wait...',
  gtinAlreadyAssigmentText: 'size code are already assigned by some other user.',
  gtinAssigmentErrorText: 'size code assignment failed. Please try again.',
  gtinAssignmentSuccessText: 'GTIN has been successfully assigned with',
  gtinUnassignedStatusText: 'Un-assigned',
  gtinAssignedStatusText: 'Assigned',
  gtinAssignSuccessDialogHeadingText: 'GTIN Assignment Result',
  gtinAssignProductSearchText: 'Search to view products for GTIN Assignment',
  productStyleLength: 6,
  productColorLength: 3,
  gtinNumberLength: 14,
  productStyleValidationErrMsg: 'Style code is required!',
  productStyleValidationLenErrMsg: 'Style code should be 6 digit long!',
  productStyleValidationDataTypeErrMsg: 'Style code should be alphanumeric!',
  productColorValidationLenErrMsg: 'Color code should be 3 digit long!',
  productColorValidationDataTypeErrMsg: 'Color code should be alphanumeric!',
  gtinNumberValidationLenErrMsg: 'GTIN number should be 14 digit long!',
  gtinNumberValidationDataTypeErrMsg: 'GTIN number should be numeric!',
  noSearchMatchFoundText: 'No Matches Found. Please try another search.',
  noBGradeSearchMatchFoundText: 'No Product data found. Please try another search.',
  refreshButtonLabel: 'Refresh',
  sessionTimeoutHeading: 'Session Timeout',
  sessionTimeoutText:
    'Your session has been timed out. Please refresh to login again.',
  lookupProductSearchText: 'Search by GTIN or Product Code to view products',
  mfgTextInputLabel: 'MFG Id',
  procurementSubmitBtnLabel: 'Initiate Replenishment Request',
  replenishmentInProcessText: 'Replenishment is in process.',
  replenishmentProcessSuccessText: 'Replenishment is in progress. Please check after sometime',
  styleCodeErrorMsg:
    'Style Code is mandatory with Color Code',
  searchResultText: 'Search Results:',
  checkStyleAndGtinFilledError: 'Either Product Style Code or GTIN number required!',
  resetButtonLabel: 'Reset Search',
  downloadDialogTitle: 'Export All',
  downloadDialogMessage:
    'Would you like to export all data? This will retrieve information from all the pages of the Search Results',
  closeDialog: 'CANCEL',
  downloadFile: 'EXPORT',
  isFileFormatBlankMsg: 'Please select either .csv or .xlxs',
};

export default defaultText;
