import axios from 'axios';
import interceptor from '../helpers/interceptor';

interceptor();
export function GET(apiUrl) {
  return axios({
    method: 'GET',
    url: apiUrl,
  });
}

export function POST(apiUrl, data) {
  return axios({
    method: 'POST',
    url: apiUrl,
    data,
  });
}