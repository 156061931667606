/**
 * Custom nike spinner component
 * Description: This file defines the nikeSpinner
 * Its function is to appear as a buffer during loading of data in a page
 * @exports NikeSpinner of @type {ReactComponent}
 * @author {Cognizant Technology Solution}
 * @flow
 */
import * as React from 'react';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import imagesPath from '../../constants/imagesPath';
import './styles.scss';

interface Props {
  onClose?: Function;
  open?: boolean;
  children?: any;
  className?: string;
}

function NikeSpinner(props: Props): React.Node {
  const {
    onClose, open, children, className
  } = props;
  return (
    <Dialog
      classes={{
        paper: 'spinner-dialog'
      }}
      className={className}
      onClose={onClose}
      disableEscapeKeyDown
      maxWidth="lg"
      open={open}
    >
      <div className="nikeSpinnerWrapper">
        <img src={imagesPath.spinner.src} alt={imagesPath.spinner.alt} />
      </div>
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
}

export default NikeSpinner;
