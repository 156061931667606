import authReducer from './authReducer';
import procurementReducer from './procurementReducer';
import productAssignmentReducer from './productAssignmentReducer';
import productLookupReducer from './productLookupReducer';

export default {
  auth: authReducer,
  productAssignment: productAssignmentReducer,
  productLookup: productLookupReducer,
  procurement: procurementReducer,
};
