/**
 * This file is used for storing the Images and its path.
 * @exports imagesPath
 * @author {Cognizant Technology Solution}
 */

import errorPage from './images/errorPage.svg';
import spinner from './images/spinner.svg';
import logo from './images/logo.png';
import notFound from './images/notFoundImage.png';

const imagesPath = {
  imgErrorPage: {
    src: errorPage,
    alt: 'ErrorImage'
  },
  spinner: {
    src: spinner,
    alt: 'spinner'
  },
  logo: {
    src: logo,
    alt: 'Logo'
  },
  NotFound: {
    src: notFound,
    alt: 'Something went wrong'
  }
};

export default imagesPath;
