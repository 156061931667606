/**
 * Nike application
 * @exports: App
 * @author {Cognizant Technology Solutions}
 * @flow
 */

import * as React from 'react';
import { Switch, Route } from 'react-router-dom';
import ErrorBoundary from './components/ErrorBoundary';
import NikeHeader from './nikeCustomComponents/nikeHeader';
import NikeFooter from './nikeCustomComponents/nikeFooter';
import NotificationDialog from './components/NotificationDialog';
import defaultText from './constants/defaultText';
import routes from './routes';
import './styles/app.scss';

interface Props {
  userName?: string;
  isSessionExpired?: boolean;
}

function App(props: Props): React.Node {
  const { isSessionExpired, userName } = props;
  return (
    <>
      <NikeHeader userName={userName} />
      <div className='mainWrapper'>
        <Switch>
          {routes.map((route) => (
            <Route
              key={route.name}
              path={route.path}
              exact={route.exact}
              render={() => (
                <ErrorBoundary>
                  <route.component />
                </ErrorBoundary>
              )}
            />
          ))}
        </Switch>
      </div>
      <NikeFooter />
      {
        isSessionExpired && (
          <NotificationDialog
            open={isSessionExpired}
            title={defaultText.sessionTimeoutHeading}
            description={defaultText.sessionTimeoutText}
            className="sessionTimeoutDialog"
            actions={
              [{
                buttonText: defaultText.refreshButtonLabel,
                onClick: () => window.location.reload()
              }]
            }
          />
        )
      }
    </>
  );
}
export default App;
