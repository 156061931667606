/**
 * File contains constants for Page Route URL.
 * The file can be imported directly wherever required.
 * @flow
 * @author {Cognizant Technology Solution}
 */

const routesUrl = {
  dashboard: '/',
  profile: '/profile',
  productLookup: '/product-lookup',
  productAssignment: '/product-assignment',
  procurement: '/procurement',
};

export default routesUrl;
