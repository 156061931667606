/**
 * This is a Auth Class used for Okta Authorization
 * Nike application
 * @author {Cognizant Technology Solutions}
 */

/* eslint-disable no-param-reassign */
import { OktaAuth } from '@okta/okta-auth-js';
import '@okta/okta-auth-js/polyfill';

const _okta = Symbol('okta');

const config = {
  issuer: process.env.REACT_APP_UPC_OKTA,
  authorizeUrl: `${process.env.REACT_APP_UPC_OKTA}/v1/authorize`,
  tokenUrl: `${process.env.REACT_APP_UPC_OKTA}/v1/token`,
};

async function httpRequestClient(method, url, args) {
  if (
    url.includes('.well-known/openid-configuration')
    || url.includes('token')
  ) {
    args.withCredentials = false;
    delete args.headers['X-Okta-User-Agent-Extended'];
  }

  if (args.data) {
    args.body = args.data;
    delete args.data;
  }

  const response = await fetch(url, { method, ...args });
  const json = await response.json();
  return { responseText: json };
}

class UPCAuth {
  constructor({
    clientId, redirectUri, storage = 'localStorage'
  }) {

    this[_okta] = new OktaAuth({
      clientId,
      redirectUri,
      httpRequestClient,
      issuer: config.issuer,
      authorizeUrl: config.authorizeUrl,
      tokenUrl: config.tokenUrl,
      tokenManager: {
        expireEarlySeconds: 60,
        autoRenew: true,
        secure: true,
        storage
      },
      cookies: {
        secure: true
      },
      services:{
        tokenService: {
          autoRenew: true
        }
      }
    });
  }

  async initialize() {
    const searchParams = new URLSearchParams(
      window.location.search.substring(1)
    );
    if (searchParams.get('code')) {
      const { tokens } = await this[_okta].token.parseFromUrl();
      this[_okta].tokenManager.add('idToken', tokens.idToken);
      this[_okta].tokenManager.add('accessToken', tokens.accessToken);
    }
  }

  login = (authorizeOptions = {}) => {
    this[_okta].token.getWithRedirect({
      ...authorizeOptions,
      scopes: [
        'openid', 'email', 'profile', 'legacy_username'
      ]
    });
  };

  logout = () => {
    this[_okta].tokenManager.clear();
  };

  getAccessToken = async () => this[_okta].tokenManager.get('accessToken');

  getIdToken = async () => this[_okta].tokenManager.get('idToken');

  isLoggedIn = async () => {
    const idToken = await this.getIdToken();
    return idToken ? this[_okta].token.verify(idToken, {}) : false;
  };

  tokenRenewListener = (key) => {
    this[_okta].tokenManager.renew(key);
  };

  startAuth = () => {
    this[_okta].start();
    this[_okta].tokenManager.on('expired', this.tokenRenewListener);
  };
}

export default UPCAuth;