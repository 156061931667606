/**
 * Custom nikeLink component
 * Description: This file defines the nikeLink
 * @exports NikeLink of @type {ReactComponent}
 * @author {Cognizant Technology Solution}
 * @flow
 */

import * as React from 'react';
import { Link } from 'react-router-dom';
import './styles.scss';

interface Props {
  url: any;
  label?: string;
  className?: string;
  children?: any;
  state?: any;
}

const NikeLink = (props: Props): React.Node => {
  const { url, label, children, className, state } = props;
  return (
    <>
      <Link to={{pathname: url, state: state}} className={className} data-testid='link'>
        {children}
        {label}
      </Link>
    </>
  );
};

export default NikeLink;
