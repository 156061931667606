/**
 * Routes
 * @author {Cognizant Technology Solutions}
 */

import React, { Suspense } from 'react';
import NikeSpinner from './nikeCustomComponents/nikeSpinner';
import routes from './constants/routesUrl';

const Dashboard = React.lazy(() => import('./pages/Dashboard'));
const Profile = React.lazy(() => import('./containers/Profile'));
const ErrorPage = React.lazy(() => import('./pages/ErrorPage'));
const ProductLookup = React.lazy(() => import('./containers/ProductLookup'));
const ProductAssignment = React.lazy(() =>
  import('./containers/ProductAssignment')
);
const Procurement = React.lazy(() => import('./containers/Procurement'));

// eslint-disable-next-line react/display-name
const getLazyComponent = (Component) => (props) => (
  <Suspense
    fallback={
      <NikeSpinner open className="lazyPageSpinner">
        <p className="lazydesc spinnerText">
          Please wait while the page loads ...
        </p>
      </NikeSpinner>
    }
  >
    <Component {...props} />
  </Suspense>
);

export default [
  {
    name: 'Dashboard',
    path: routes.dashboard,
    exact: true,
    component: getLazyComponent(Dashboard),
  },
  {
    name: 'Product Lookup',
    path: routes.productLookup,
    exact: true,
    component: getLazyComponent(ProductLookup),
  },
  {
    name: 'Product Assignment',
    path: routes.productAssignment,
    exact: true,
    component: getLazyComponent(ProductAssignment),
  },
  {
    name: 'Profile',
    path: routes.profile,
    exact: true,
    component: getLazyComponent(Profile),
  },
  {
    name: 'Procurement',
    path: routes.procurement,
    exact: true,
    component: getLazyComponent(Procurement),
  },
  {
    name: 'Error Page',
    path: '*',
    exact: false,
    component: getLazyComponent(ErrorPage),
  },
];
