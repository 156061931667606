/**
 * Store
 * @author {Cognizant Technology Solutions}
 * @flow
 */
import { configureStore } from '@reduxjs/toolkit';
import rootReducer from '../reducers';

const store: Object = configureStore({
  reducer: rootReducer,
});

export default store;
