/**
 * This file is being used as reducer for ProductAssignment
 * @exports ProductAssignment Reducer as default
 * @author {Cognizant Technology Solutions}
 * @flow
 */

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as ApiService from '../services/services';
import * as url from '../constants/apiUrl';
import appConfig from '../constants/appConfig';

// Thunk to fetch B-Grade Products
export const fetchProducts: Function = createAsyncThunk(
  'productAssignment/fetchProducts',
  async (data: Object) => {
    const response = await ApiService.POST(url.GET_PRODUCT_LOOKUP, {
      ...data,
      limit: appConfig.rowPerPage,
    });
    return response;
  }
);

// Thunk for Update GTIN Assignment
export const assignGtin: Function = createAsyncThunk(
  'productAssignment/assignGtin',
  async (data: Object) => {
    const response = await ApiService.POST(url.POST_PRODUCT_GTIN_ASSIGNMENT, data);
    return response;
  }
);

export const productAssignmentSlice: Object = createSlice({
  name: 'productAssignment',
  initialState: {
    data: {},
    assignmentData: null,
    isSearched: false,
    loading: false,
    error: null,
  },
  reducers: {
    resetProductsData: (state) => {
      state.data = {};
      state.isSearched = false;
      state.loading = false;
      state.error = null;
      state.assignmentData = null;
    },
    resetAssignmentData: (state) => {
      state.assignmentData = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProducts.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchProducts.fulfilled, (state, action) => {
      state.data = action.payload;
      state.isSearched = true;
      state.loading = false;
    });
    builder.addCase(fetchProducts.rejected, (state, action) => {
      state.error = action.error.message;
      state.loading = false;
    });
    builder.addCase(assignGtin.pending, (state) => {
      state.error = null;
      state.loading = true;
    });
    builder.addCase(assignGtin.fulfilled, (state, action) => {
      state.loading = false;
      state.assignmentData = action.payload;
    });
    builder.addCase(assignGtin.rejected, (state, action) => {
      state.error = action.error.message;
      state.loading = false;
    });
  },
});

export const { resetAssignmentData, resetProductsData } = productAssignmentSlice.actions;

export default productAssignmentSlice.reducer;