/**
 * nike custom dialog component
 * customised modal dialog with an optional title and actions
 * @exports NikeDialog of @type {ReactComponent}
 * @author {Cognizant Technology Solutions}
 * @flow
 */
import * as React from 'react';
import {
  Dialog, DialogTitle, DialogContent, DialogActions
} from '@mui/material';

interface Props {
  disableEscapeKeyDown?: boolean;
  title?: Object;
  renderContent: Object;
  renderActions?: Object;
  dividers?: boolean;
  open?: any;
  className?: string;
}

export default function NikeDialog(props: Props): React.Node {
  const {
    disableEscapeKeyDown,
    title,
    open,
    renderContent,
    renderActions,
    dividers,
    className,
    ...otherProps
  } = props;

  /* if title props is passed, the function renders DialogTitle component otherwise renders null */

  const getTitle = () => (title ? (
    <DialogTitle id="confirmation-dialog-title">{title}</DialogTitle>
  ) : null);

  /**
   * If renderActions props is passed, the function renders DialogActions component
   * otherwise renders null
  */

  const getActions = () => (renderActions ? (
    <DialogActions>
      {renderActions}
    </DialogActions>
  ) : null);

  return (
    <Dialog
      className={`${className || ''} nikeDialogRoot`}
      classes={{
        root: 'dialog-root',
        scrollPaper: 'dialog-scrollPaper',
        paper: 'paperStyle'
      }}
      disableEscapeKeyDown={disableEscapeKeyDown}
      maxWidth="lg"
      aria-labelledby="confirmation-dialog-title"
      open={open}
      data-testid="nikeDialog"
      {...otherProps}
    >
      {getTitle()}
      <DialogContent dividers={dividers}>
        {renderContent}
      </DialogContent>
      {getActions()}
    </Dialog>
  );
}
