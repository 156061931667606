/**
 * This component can be used for all the notification style
 * dialogs.
 * @exports NotificationDialog
 * @author {Cognizant Technology Solution}
 * @flow
 */

import * as React from 'react';
import PropTypes from 'prop-types';
import NikeButton from '../../nikeCustomComponents/nikeButton';
import NikeDialog from '../../nikeCustomComponents/nikeDialog';
import './styles.scss';

interface Action {
  buttonText: string;
  onClick: Function;
}

interface Props {
  image?: {
    src: Object;
    alt: string;
  };
  title?: string;
  description?: string;
  actions?: Array<Action>;
  open: boolean;
  onBackdropClick?: Function;
  className?: string;
}

export default function NotificationDialog(props: Props): React.Node {
  const {
    image,
    title,
    description,
    actions,
    open,
    onBackdropClick,
    className
  } = props;
  /**
     * This method is used as handler for rendering
     * the notification dialog content.
     */
  const dialogContent = (
    <>
      {
        image && (
          <div className="imageContainer">
            <img src={image.src} alt={image.alt} />
          </div>
        )
      }
      <div className="textContainer">
        <div className="title">{title}</div>
        <span className="description">
          {description}
        </span>
      </div>
    </>
  );

  /**
    * This method is used as handler for rendering
    * the notification dialog actions like close, download etc.
    */
  const renderActions = (
    <div>
      {
        actions && actions.map((action) => (
          <NikeButton
            key={action.buttonText}
            buttonText={action.buttonText}
            onClick={action.onClick}
            variant="contained"
          />
        ))
      }
    </div>
  );

  return (
    <NikeDialog
      onBackdropClick={onBackdropClick}
      open={open}
      className={`notificationDialog ${className || ''}`}
      renderContent={dialogContent}
      {...(actions !==undefined && actions.length > 0 && { renderActions: renderActions })}
    />
  );
}

NotificationDialog.propTypes = {
  actions: PropTypes.array
};
