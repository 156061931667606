/**
 * Redux Container component for AppAuthorization
 * @author {Cognizant Technology Solutions}
 * @flow
 */

import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setAuthData } from '../../reducers/authReducer';
import AppAuthorization from '../../components/AppAuthorization';
import store from '../../store';

const AppAuthorizationContainer = (): React.Node => {
  const dispatch = useDispatch();

  const isSessionExpired = useSelector(state => state.auth.sessionExpired);

  const getAuthKey = (key: string) => {
    const state = store.getState();

    try {
      return state.auth.data[key];
    } catch (e) {
      console.log('Err', e);
    }
    return null;
  };

  const setAuthKey = (key: string, data: Object) => {
    dispatch(setAuthData({
      [key]: data
    }));
  };

  return (
    <AppAuthorization
      getAuthKey={getAuthKey}
      setAuthKey={setAuthKey}
      isSessionExpired={isSessionExpired}
    />
  );
};

export default AppAuthorizationContainer;
