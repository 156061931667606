/**
 * Helper for user group * @author {Cognizant Technology Solutions}
 * @flow
 */

import appConfig from '../constants/appConfig';

const userAccess = (userGroup: Array<string>, groupName: string = 'writeAccessGroupUser'): boolean => {
  return appConfig[groupName].some(r=> userGroup.includes(r));
};

export default userAccess;