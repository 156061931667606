/**
 * nike custom component - NikeButton
 * default color theme will be primary from MUI.
 * can be customized based on the variant
 * @exports NikeButton
 * @author {Cognizant Technology Solutions}
 * @flow
 */
import * as React from 'react';
import Button from '@mui/material/Button';

interface Props {
  color?: string;
  buttonText?: Object;
  className?: string;
  variant?: string;
  onClick?: Function;
}

export default function NikeButton(props: Props): React.Node {
  const {
    color,
    buttonText,
    variant,
    onClick,
    className,
    ...other
  } = props;

  const classname = `nikeButton ${className || ''}`;
  const buttonTheme = color || 'primary';
  return (
    <Button
      color={buttonTheme}
      variant={variant}
      className={classname}
      onClick={onClick}
      sx={{ textTransform: 'inherit' }}
      {...other}
    >
      {buttonText}
    </Button>
  );
}

NikeButton.defaultProps = {
  size: 'medium',
  variant: 'contained'
};
