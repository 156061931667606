/**
 * App Configuration file
 * @author {Cognizant Technology Solutions}
 */

const appConfig = {
  rowsPerPageOptions: [50, 100, 150],
  rowPerPage: 50,
  snackbarAutohideDuration: 4000,
  writeAccessGroupUser: ['App.GOAL.GTIN.WRITE','EXT.App.gtin.Write'],
  techAccessGroupUser: ['App.GOAL.GTIN.TECH', 'EXT.App.gtin.TECH'],
};

export default appConfig;