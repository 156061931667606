/**
 * nike custom footer component
 * @exports NikeFooter of @type {ReactComponent}
 * @author {Cognizant Technology Solutions}
 * @flow
 */
import * as React from 'react';
import defaultText from '../../constants/defaultText';
import './styles.scss';

export default function NikeFooter(): React.Node {
  return (
    <div className="nikeFooter" data-testid="nikeFooter">
      <p>&copy; {defaultText.footerTxt}</p>
    </div>
  );
}
