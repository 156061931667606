/**
 * nike custom component-Error Boundary
 * @exports ErrorBoundary
 * @author {Cognizant Technology Solutions}
 * @flow
 */

import * as React from 'react';
import PropTypes from 'prop-types';
import imagesPath from '../../constants/imagesPath';
import defaultText from '../../constants/defaultText';
import './styles.scss';

interface Props {
  children: Object;
}

interface State {
  error: any;
  errorInfo: any;
}

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error: any, errorInfo: any) {
    /** Catch errors in any components below and re-render with error message */
    this.setState({
      error,
      errorInfo
    });
  }

  render(): React.Node {
    const { state } = this;
    const { children } = this.props;

    if (state.errorInfo) {
      console.log('Error: ', state.error);
      /** Error path */
      return (
        <div className="errorBoundary">
          <img src={imagesPath.imgErrorPage.src} alt={imagesPath.imgErrorPage.alt} />
          <div className="error">{defaultText.errorTxt}</div>
          <div>{defaultText.errorDescription}</div>
        </div>
      );
    }

    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired
};

export default ErrorBoundary;
