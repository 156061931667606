/**
 * This is a functional component which is being
 * used for OKTA_AUTHORISED LOGIN.
 * @author {Cognizant Technology Solution}
 * @flow
 */

import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import UPCAuth from '../../helpers/UPCAuth';
import NikeSpinner from '../../nikeCustomComponents/nikeSpinner';
import defaultText from '../../constants/defaultText';
import NotificationDialog from '../NotificationDialog';
import App from '../../App';


interface Props {
  isSessionExpired?: boolean,
  setAuthKey: Function;
  getAuthKey: Function;
}

// $FlowFixMe
export function WithAuthorization(AppComponent: Function, props: Props) {
  const { setAuthKey, getAuthKey, isSessionExpired } = props;
  const [redirectUri] = window.location.href.split('?');
  
  const UPCAuthClient = new UPCAuth({
    clientId: process.env.REACT_APP_UPC_CLIENT_ID,
    storage: {
      getItem: (key) => getAuthKey(key),
      setItem: (key, value) => setAuthKey(key, value)
    },
    redirectUri: `${redirectUri}`
  });

  return () => {
    const history = useHistory();
    const [isLoggedIn, setIsLoggedIn] = useState(null);
    const [username, setUsername] = useState(null);

    /**
     * This function returns the appropriate component for
     * the login status.
     * renders the component passed only if it is logged in,
     * and shows an error dialog on failure. and a spinner on intermediate state.
     */

    const getComponent = () => {
      if (isLoggedIn === null) {
        return (
          <NikeSpinner className="loginSpinner" open={isLoggedIn === null}>
            {defaultText.authReq}
          </NikeSpinner>
        );
      }
      if (!isLoggedIn) {
        return (
          <NotificationDialog
            open
            className="loginNotification"
            title={defaultText.authFailed}
            description={defaultText.authMsg}
          />
        );
      }

      return (
        <AppComponent
          userName={username}
          isSessionExpired={isSessionExpired}
        />
      );
    };

    useEffect(() => {
      UPCAuthClient.initialize()
        .then(async () => {
          /** Otherwise, check if the app already has a valid token
          * If so, get the id token from storage and set the component state */
          const isAlreadyLoggedIn = await UPCAuthClient.isLoggedIn();
          if (isAlreadyLoggedIn) {
            const idToken = await UPCAuthClient.getIdToken();
            setIsLoggedIn(true);
            setUsername(idToken && idToken.claims && idToken.claims.name?.split(' ')[0] || '');
          }
        })
        .catch((error) => {
          console.log('Error: ', error);
        });

      if (window.location.search.includes('error')) {
        setIsLoggedIn(false);
      } else if (!history.location.search.includes('code=')) {
        if (isLoggedIn === null) {
          UPCAuthClient.login();
        }
      }

      UPCAuthClient.startAuth();
    }, []);

    return (getComponent());
  };
}

export default function AuthWrapper(props: Props): React$Element<*> {
  const AuthorizedApp = WithAuthorization(App, props);

  return (<AuthorizedApp />);
}
