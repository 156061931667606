/**
 * This file is being used as reducer for Procurement
 * @exports Procurement Reducer as default
 * @author {Cognizant Technology Solutions}
 * @flow
 */

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as ApiService from '../services/services';
import * as url from '../constants/apiUrl';

// Thunk to initate procurement
export const initiateProcurement: Function = createAsyncThunk(
  'procurement/requestProcurement',
  async (data: Object) => {
    const response = await ApiService.POST(url.POST_INITIATE_PROCUREMENT, data);
    return response;
  }
);

export const procurementSlice: Object = createSlice({
  name: 'procurement',
  initialState: {
    data: {},
    loading: false,
    error: null,
  },
  reducers: {
    resetProcurementData: (state) => {
      state.data = {};
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(initiateProcurement.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(initiateProcurement.fulfilled, (state, action) => {
      state.data = action.payload;
      state.loading = false;
    });
    builder.addCase(initiateProcurement.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export const { resetProcurementData } = procurementSlice.actions;

export default procurementSlice.reducer;