/**
 * This file is being used as reducer for ProductLookup
 * @exports ProductLookup Reducer as default
 * @author {Cognizant Technology Solutions}
 * @flow
 */

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as ApiService from '../services/services';
import * as url from '../constants/apiUrl';

// Thunk to fetch Products
export const fetchProducts: Function = createAsyncThunk(
  'productLookup/fetchProducts',
  async (data: Object) => {
    const response = await ApiService.POST(url.GET_PRODUCT_LOOKUP, data);
    return response;
  }
);

// Thunk to Download Files
export const downloadFile: Function = createAsyncThunk(
  'productLookup/downloadFile',
  async (data: Object) => {
    const response = await ApiService.POST(url.DOWNLOAD_PRODUCT_REPORT, data);
    return response;
  }
);

export const productLookupSlice: Object = createSlice({
  name: 'productLookup',
  initialState: {
    data: {},
    reportData: null,
    isSearched: false,
    loading: false,
    error: null,
  },
  reducers: {
    resetProductsData: (state) => {
      state.data = {};
      state.reportData = null;
      state.isSearched = false;
      state.loading = false;
      state.error = null;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProducts.pending, (state) => {
      state.loading = true; 
      state.error = null;
    });
    builder.addCase(fetchProducts.fulfilled, (state, action) => {
      state.data = action.payload;
      state.loading = false;
      state.isSearched = true;
    });
    builder.addCase(fetchProducts.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(downloadFile.pending, (state) => {
      state.loading = false; 
      state.error = null;
    });
    builder.addCase(downloadFile.fulfilled, (state, action) => {
      state.reportData = action.payload;
      state.loading = false;
      state.isSearched = true;
    });
    builder.addCase(downloadFile.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  }
});

export const { resetProductsData } = productLookupSlice.actions;
export default productLookupSlice.reducer;