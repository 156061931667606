/**
 * nike custom Header component
 * @exports NikeHeader of @type {ReactComponent}
 * @author {Cognizant Technology Solutions}
 * @flow
 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import AccountCircle from '@mui/icons-material/AccountCircle';
import HomeIcon from '@mui/icons-material/Home';
import BadgeIcon from '@mui/icons-material/Badge';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Link, NavLink } from 'react-router-dom';
import NikeLink from '../nikeLink';
import imagesPath from '../../constants/imagesPath';
import defaultText from '../../constants/defaultText';
import routes from '../../constants/routesUrl';
import userAccessHelper from '../../helpers/userAccess';
import './styles.scss';

interface Props {
  userName?: string;
}

export default function NikeHeader({
  userName,
}: Props): React$Element<*> {
  const [anchorEl, setAnchorEl] = useState(null);
  const userAccessGroups = useSelector(state => state.auth.data?.userDetails?.group || []);
  const hasSupportAccess: boolean = userAccessHelper(userAccessGroups, 'techAccessGroupUser');

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar position="static" className='appHeader' sx={{ bgcolor: 'black' }} data-testid="appHeader">
      <Toolbar>
        <div className="logoWrapper flx-gro-sm-1">
          <NikeLink
            url={routes.dashboard}
            className="nikeLink mr3-sm"
          >
            <img src={imagesPath.logo.src} alt={imagesPath.logo.alt} /> {defaultText.appTitle}
          </NikeLink>
        </div>
        <div className='menuLinksWrapper'>
          <Tooltip title="Dashboard" arrow>
            <IconButton aria-label="dashboard" size="large">
              <NavLink
                to={routes.dashboard}
                className="nikeLink"
                exact
              >
                <HomeIcon />
              </NavLink>
            </IconButton>
          </Tooltip>
          {hasSupportAccess && (
            <Tooltip title="Procurement" arrow>
              <IconButton aria-label="procurement" size="large">
                <NavLink
                  to={routes.procurement}
                  className="nikeLink"
                  data-testid="procurementLink"
                  exact
                >
                  <BadgeIcon />
                </NavLink>
              </IconButton>
            </Tooltip>
          )}
        </div>
        <div className='pl1-sm'>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
            data-testid="profileMenu"
          >
            <AccountCircle />
            <span className='userNameLabel'>{userName}</span>
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={handleClose}>
              <Link to={routes.profile}>Profile</Link>
            </MenuItem>
          </Menu>
        </div>
      </Toolbar>
    </AppBar>
  );
}

NikeHeader.propTypes = {
  userName: PropTypes.string,
};

NikeHeader.defaultProps = {
  userName: '',
};
